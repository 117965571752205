import AnchorText from './AnchorText';
import CountPicker from './CountPicker';
import InputCheckbox from './Input/InputCheckbox';
import Button from '@/components/Button';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { navigate } from 'gatsby';
import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

export interface CartItem {
  categoryCodeName?: string;
  basketUserId: string;
  count: number;
  firstRegisterDate: string;
  piIsImg?: string;
  productCode: string;
  productName: string;
  productNameEn: string;
  productPrice: number;
  sumPrice: string;
  // eslint-disable-next-line camelcase
  imgProductAttImgNo1_50?: string;
  // eslint-disable-next-line camelcase
  imgProductAttImgNo1_140?: string;
  // eslint-disable-next-line camelcase
  imgProductAttImgNo1_290?: string;
  // eslint-disable-next-line camelcase
  imgProductAttImgNo1_420?: string;
}

const ListContainer = styled.div`
  .link-item {
    position: absolute;
    right: 0;
    top: 5px;
    color: #828385;
    text-decoration: underline;
  }
`;

const Header = styled.div`
  position: relative;

  .link-item {
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const ItemList = styled.ul`
  margin-top: 15px;
  padding-bottom: 40px;
  border-top: 1px solid #b7b8ba;

  & > li {
    display: flex;
    position: relative;
    padding: 25px 0;
    border-bottom: 1px solid #e5e6e8;

    &:last-child {
      border-bottom: 0;
    }
  }
`;
const ColChk = styled.div`
  width: 33px;
`;

const ColImg = styled.div`
  width: 160px;
  margin-left: 9px;

  ${breakpoint(`tablet`)} {
    display: none;
  }
  .img-box {
    padding-top: 100%;
    border: 1px solid #e5e6e8;
  }
`;

const ImgBox = styled.div`
  padding-top: 100%;
  border: 1px solid #e5e6e8;
  cursor: pointer;
`;

const ColDec = styled.div`
  position: relative;
  width: calc(100% - 195px);
  padding-left: 32px;
  padding-top: 5px;
  line-height: 1;

  ${breakpoint(`tablet`)} {
    width: calc(100% - 33px);
    padding: 0;
  }
`;

const DecWrap = styled.div`
  position: relative;

  ${ImgBox} {
    display: none;
  }

  ${breakpoint(`tablet`)} {
    padding-left: 130px;
    min-height: 110px;

    ${ImgBox} {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 110px;
      height: 110px;
      padding-top: 0;
    }
  }

  .tit {
    display: block;
  }

  p {
    margin-top: 15px;
    font-weight: 100;
  }

  .price {
    display: block;
    margin-top: 25px;
  }
`;

const BttFlex = styled.ul`
  margin-top: 22px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ${breakpoint(`tablet`)} {
    margin: 0px -2px;
    margin-top: 8px;

    .btn {
      width: auto;
      padding: 5px 16px;
    }
  }

  & > li {
    margin-right: 16px;

    &:last-child {
      width: calc(100% - 260px);
      margin-right: 0;
    }

    ${breakpoint(`tablet`)} {
      margin: 0;
      padding: 0 8px;
      width: calc((100% - 126px) / 2) !important;

      &:first-child {
        width: 126px !important;
      }
    }

    ${breakpoint(400)} {
      padding: 0 2px;
      .btn {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }
    }
    ${breakpoint(360)} {
      .btn {
        font-size: 14px;
      }
    }
  }
`;

const EmptyMessage = styled.div`
  padding: 40px 40px 0 40px;
`;

interface CartListProps {
  items: CartItem[];
  onSelectedIdsChange: (ids: string[]) => void;
  onModifyItem: (item: CartItem, value: number) => void;
  onDeleteItems: (ids: string[]) => void;
  onOpenForm: (ids: string[]) => void;
}

const CartList: FC<CartListProps> = ({
  items,
  onSelectedIdsChange,
  onModifyItem,
  onDeleteItems,
  onOpenForm,
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  // id 변할때 이벤트 발생
  const handleSelectedIdsChange = useCallback(
    (ids: string[]) => {
      setSelectedIds(ids);
      onSelectedIdsChange(ids);
    },
    [onSelectedIdsChange],
  );
  // 체크박스 누를시
  const handleCheckboxChange = useCallback(
    (itemId: string, checked: boolean) => {
      if (checked) {
        handleSelectedIdsChange([...selectedIds, itemId]);
      } else {
        handleSelectedIdsChange(selectedIds.filter((id) => id !== itemId));
      }
    },
    [handleSelectedIdsChange, selectedIds],
  );

  const isAllChecked = useMemo(() => items.length === selectedIds.length, [
    items.length,
    selectedIds.length,
  ]);

  const handleAgreeAll: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      handleSelectedIdsChange(items.map((item) => item.productCode));
    } else {
      handleSelectedIdsChange([]);
    }
  };

  return (
    <ListContainer>
      <Header>
        <InputCheckbox
          name="isAllChecked"
          label={`${selectedIds.length} 개 상품 선택`}
          onChange={handleAgreeAll}
          checked={isAllChecked}
        />
        {!!selectedIds.length && (
          <AnchorText
            className="link-item"
            onClick={() => onDeleteItems(selectedIds)}
          >
            선택삭제
          </AnchorText>
        )}
      </Header>
      <ItemList>
        {items.length > 0 ? (
          items.map((row) => (
            <li key={row.productCode}>
              <ColChk>
                <InputCheckbox
                  checked={selectedIds.includes(row.productCode)}
                  onChange={(e) =>
                    handleCheckboxChange(row.productCode, e.target.checked)
                  }
                />
              </ColChk>
              <ColImg>
                <ImgBox
                  className="img-box"
                  onClick={() =>
                    navigate(`/involve/individual/ig/${row.productCode}`)
                  }
                  css={`
                    background-image: url(${row.imgProductAttImgNo1_290});
                  `}
                />
              </ColImg>
              <ColDec>
                <DecWrap>
                  <ImgBox
                    className="img-box"
                    onClick={() =>
                      navigate(`/involve/individual/ig/${row.productCode}`)
                    }
                    css={`
                      background-image: url(${row.imgProductAttImgNo1_290});
                    `}
                  />
                  <strong className="tit">{row.productName}</strong>
                  <p>{row.productNameEn}</p>
                  <strong className="price">
                    {(
                      Number(row.productPrice) * Number(row.count)
                    ).toLocaleString()}
                    ({row.count}개)
                  </strong>
                </DecWrap>

                <BttFlex>
                  <li>
                    <CountPicker
                      min={1}
                      value={Number(row.count)}
                      onChange={(value) => onModifyItem(row, value)}
                    />
                  </li>
                  <li>
                    <Button
                      size="sm"
                      width={152}
                      className="btn"
                      onClick={() => onOpenForm([row.productCode])}
                    >
                      후원하기
                    </Button>
                  </li>
                </BttFlex>
                <AnchorText
                  className="link-item"
                  onClick={() => onDeleteItems([row.productCode])}
                >
                  삭제
                </AnchorText>
              </ColDec>
            </li>
          ))
        ) : (
          <EmptyMessage>장바구니가 비어있습니다</EmptyMessage>
        )}
      </ItemList>
    </ListContainer>
  );
};
export default CartList;
