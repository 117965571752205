import Button, { BtnClose } from '../Button';
import Modal, { ModalProps } from '../Modal';
import { Tit } from '../Titles';
import { SaveLifeControllerService } from '@/__generated__/FrontApi';
import CartList, { CartItem } from '@/components/CartList';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { navigate } from 'gatsby';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const PopupTitle = styled.div`
  border-bottom: 1px solid #e5e6e8;
  position: relative;
  padding: 22px 24px;

  ${BtnClose} {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const FormContainer = styled.div`
  padding: 24px 20px;
  padding-bottom: 40px;
`;
const FormTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 0;
  border-top: 1px solid #b7b8ba;
  border-bottom: 1px solid #b7b8ba;
`;

const BtmnFlex = styled.ul`
  display: flex;
  margin: 0 -4px;
  margin-top: 40px;

  & > li {
    padding: 0 16px;
    width: 50%;
  }
  ${breakpoint(640)} {
    & > li {
      padding: 0 4px;
    }
  }
`;

type CartModuleProps = ModalProps;

const CartModule: FC<CartModuleProps> = ({ onRequestClose, ...props }) => {
  const [items, setItems] = useState<CartItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const loadItems = useCallback(async () => {
    const {
      data: _data,
    } = await SaveLifeControllerService.getListGiftCartUsingGet();
    // 타입에러 보정
    const data = _data as any;
    if (data) {
      setItems(
        data.map((cart: CartItem) => ({
          ...cart,
          productPrice: Number(cart.productPrice),
          count: Number(cart.count),
        })),
      );
    }
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const totalAmount = useMemo(
    () =>
      items.reduce(
        (result, item) => result + item.productPrice * item.count,
        0,
      ),
    [items],
  );
  const totalCount = useMemo(
    () => items.reduce((result, item) => result + item.count, 0),
    [items],
  );

  // 상품수량 저장
  const saveItem = useCallback(
    _.debounce(async (item: CartItem) => {
      const {
        resultCode,
      } = await SaveLifeControllerService.modifyCountGiftCartUsingPost({
        productCode: item.productCode,
        count: `${item.count}`,
      });
      if (resultCode === `succcess`) {
        // 수정 성공하면 리로딩
        loadItems();
      }
    }, 500),
    [loadItems],
  );

  // 상품수량 수정시
  const handleModifyItem = useCallback(
    (target: CartItem, value: number) => {
      const newItem = {
        ...target,
        count: value,
      };
      const newItems = items.map((item) => (item === target ? newItem : item));
      setItems(newItems);
      // debounce
      saveItem(newItem);
    },
    [items, saveItem],
  );

  // 삭제시
  const handleDeleteItems = useCallback(
    async (ids: string[]) => {
      const {
        resultCode,
      } = await SaveLifeControllerService.deleteGiftCartUsingPost({
        productCodeList: ids,
      });
      if (resultCode === `success`) {
        // 수정 성공하면 리로딩
        loadItems();
      }
    },
    [loadItems],
  );

  const navigateToForm = useCallback(
    (ids: string[]) => {
      const targetItems = items.filter((item) =>
        ids.includes(item.productCode),
      );
      navigate(`/involve/individual/ig/form/`, {
        state: {
          items: targetItems,
        },
      });
    },
    [items],
  );

  return (
    <Modal onRequestClose={onRequestClose} maxWidth="712px" {...props}>
      <PopupTitle>
        <h2>
          <Tit size="s3">장바구니</Tit>
        </h2>
        <BtnClose onClick={() => onRequestClose()}>
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      <form>
        <FormContainer>
          <CartList
            items={items}
            onSelectedIdsChange={(ids) => setSelectedIds(ids)}
            onModifyItem={handleModifyItem}
            onDeleteItems={handleDeleteItems}
            onOpenForm={navigateToForm}
          />
          <FormTotal>
            <Tit size="s3">총 후원금액</Tit>
            <Tit size="s2" color="sky">
              {totalAmount.toLocaleString()}원({totalCount}개)
            </Tit>
          </FormTotal>
          <BtmnFlex>
            <li>
              <Button
                full
                disabled={!selectedIds.length}
                onClick={() => navigateToForm(selectedIds)}
              >
                선택 선물 후원하기
              </Button>
            </li>
            <li>
              <Button
                color="yellow"
                full
                onClick={() =>
                  navigateToForm(items.map((item) => item.productCode))
                }
              >
                전체 선물 후원하기
              </Button>
            </li>
          </BtmnFlex>
        </FormContainer>
      </form>
    </Modal>
  );
};

export default CartModule;
