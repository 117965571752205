import InputNumber from './Input/InputNumber';
import IconMinus from '@/assets/img/icons/icon_count_minus@2x.png';
import IconPlus from '@/assets/img/icons/icon_count_plus@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const CountContainer = styled.div``;
const CountButton = styled.button<{ disable?: boolean }>`
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  img {
    width: 100%;
  }
  ${({ disable }) => disable && `opacity: 0.6;`}
  ${breakpoint(`mobile`)} {
    width: 34px;
    height: 34px;
  }
`;
const CountNumber = styled.span`
  display: inline-block;
  width: 60px;
  height: 44px;
  text-align: center;
  border-top: 1px solid #f2f2f3;
  border-bottom: 1px solid #f2f2f3;
  vertical-align: top;
  line-height: 42px;
  ${breakpoint(`mobile`)} {
    width: 42px;
    height: 34px;
  }
`;
const CountInput = styled(InputNumber)`
  height: 42px;
  vertical-align: top;
  border: none;
  text-align: center;
  ${breakpoint(`mobile`)} {
    height: 32px;
  }
`;
interface CounterProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  min?: number;
  max?: number;
  value: number;
  onChange: (value: number) => void;
}

const CountPicker: FC<CounterProps> = ({
  min = 1,
  max,
  value,
  onChange,
  ...props
}) => {
  const countPlus = () => {
    const nextValue = value + 1;
    onChange?.(max !== undefined ? Math.min(nextValue, max) : nextValue);
  };

  const countMinus = () => {
    const nextValue = value - 1;
    onChange?.(Math.max(nextValue, min));
  };

  return (
    <CountContainer>
      <CountButton
        type="button"
        onClick={() => {
          countMinus();
        }}
      >
        <img src={IconMinus} alt="수량감소" />
      </CountButton>
      <CountNumber>
        <CountInput
          value={value}
          min={min}
          allowNegative={false}
          onValueChange={({ floatValue }) => {
            onChange(
              floatValue && Number.isInteger(floatValue) ? floatValue : min,
            );
          }}
          {...props}
        />
      </CountNumber>
      <CountButton
        type="button"
        onClick={() => {
          countPlus();
        }}
      >
        <img src={IconPlus} alt="수량증가" />
      </CountButton>
    </CountContainer>
  );
};

export default CountPicker;
