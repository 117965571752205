import Image from './Image';
import MainTabButton from './MainTabButton';
import SwiperOverflowHidden from './Swiper/SwiperOverflowHidden';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import { Link } from 'gatsby';
import React, {
  FC,
  ImgHTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// CSS
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';
import { GtmButtonDonate } from './Button.gtm';
import { SelectItemData, SelectItemDefaultIGData } from '@/helpers/GtmHelper';

SwiperCore.use([Navigation, Scrollbar, A11y]);

const PannelContainer = styled.div`
  overflow-x: auto;
`;

const UiTabPanel = styled.ul`
  background: #fff;
  display: inline-flex;

  & > li {
    margin-right: 16px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TabBanner = styled.header`
  margin: 32px 0;

  figure {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background: #1cabe2;

    .img-wrap {
      flex: 0 0 50%;

      img {
        width: 100%;
      }
    }

    figcaption {
      padding: 0 64px 0 16px;
      color: #ffffff;

      h3 {
        letter-spacing: -1.2px;
        margin-bottom: 16px;
      }

      p {
        line-height: 2;
        letter-spacing: -0.8px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    margin: 24px -20px;

    figure {
      flex-direction: column;

      .img-wrap {
        width: 100%;
      }

      figcaption {
        flex: 0 0 100%;
        padding: 20px 40px 20px 16px;

        h3 {
          letter-spacing: -0.8px;
        }
      }
    }
  }
`;

export const CardLayout = styled.div`
  width: 100%;
`;

export const CardImage = styled.div`
  width: 100%;
  overflow: hidden;
  border: solid 1px #e5e6e8;

  .img-box {
    padding-top: 100%;
  }
`;

export const CardSource = styled(Image)`
  max-width: 100%;
`;
export const CardTextArea = styled.div`
  padding: 24px 24px 0 0;

  h4 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    color: #2d2926;
  }

  h5 {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    letter-spacing: -0.8px;
    color: #2d2926;
  }

  p {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: -1.2px;
    color: #2d2926;
  }

  ${breakpoint(`mobile`)} {
    padding: 16px 16px 0 0;

    h4 {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
    }

    h5 {
      margin-bottom: 16px;
      font-size: 14px;
    }

    p {
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: -0.8px;
    }
  }
`;

const SwiperCustom = styled(SwiperOverflowHidden)`
  padding: 0;
  overflow: hidden;
  .swiper-container {
    margin: 0 -16px;
  }

  .swiper-slide {
    overflow: hidden;
    width: 25%;
    padding: 0 16px;
  }
  .swiper-button-next {
    margin-right: 0;
    right: 30px;
  }
  .swiper-button-prev {
    margin-right: 0;
    right: 120px;
  }

  ${breakpoint(`tablet`)} {
    .swiper-container {
      margin: 0 -13px;
    }

    .swiper-slide {
      width: 33.33%;
      padding: 0 13px;
    }
    .swiper-scrollbar {
      left: 13px;
    }
  }

  ${breakpoint(`mobile`)} {
    .swiper-slide {
      width: 50%;
    }
  }
`;

const LinkItem = styled(Link)<{ active?: boolean }>`
  /* ${({ active }) => {
    if (active) {
      return `
        display: block;
      `;
    }
    return `display: none;`;
  }} */
`;
const ProductList = styled.li``;

interface ProductListProps {
  categories: CategoryData[];
  products: ProductData[];
  tabs: TabData[];
  ExtraButton?: ReactElement;
  SelectItemDefaultData: SelectItemData;
}

export interface CategoryData {
  id: number | string;
  categoryCode: number | string;
  categoryCodeName: string;
  contentKey?: any;
}
export interface ProductData {
  id: number | string;
  categoryCode?: number | string;
  categoryCodeName: string;
  productCode: string | number;
  productPrice: string;
  productName: string;
  productNameEn: string;
  imgPath: ImgHTMLAttributes<any>['src'];
}

export interface TabData {
  id: string | number;
  image: string;
  subject: string;
  content: string;
}

export const TabPanel: FC<ProductListProps> = ({
  categories,
  products,
  tabs,
  ExtraButton,
  SelectItemDefaultData,
}) => {
  const [contentIndex, setContentIndex] = useState(0);
  const [contentKey, setContentKey] = useState(`04100`);

  const onButtonClick = useCallback((index: number) => {
    setContentIndex(index);
  }, []);

  useEffect(() => {
    const { swiper } = document.querySelector(`.swiper-container`);
    swiper.update();
    swiper.slideTo(0);
  }, [contentKey]);

  const pdtKey = contentKey;
  const pdtCategory = products;
  const categoryListSet = pdtCategory.filter((code) =>
    code.categoryCode?.includes(String(pdtKey)),
  );
  return (
    <>
      <PannelContainer>
        <UiTabPanel className="ui-tabpanel-list">
          {categories.map((category, index) => (
            <>
              <ProductList key={category.id}>
                <MainTabButton
                  active={contentIndex === index}
                  type="button"
                  key={category.id}
                  onClick={() => {
                    onButtonClick(index);
                    setContentKey(category.categoryCode);
                  }}
                >
                  {category.categoryCodeName}
                </MainTabButton>
              </ProductList>
            </>
          ))}
        </UiTabPanel>
      </PannelContainer>
      <div
        className={`ui-tabpanel-contents ui-tabpanel-contents-${contentIndex}`}
      >
        <article>
          <TabBanner>
            <figure>
              <div className="img-wrap">
                <Image
                  pcSrc={tabs[contentIndex].image}
                  mobileSrc={tabs[contentIndex].image}
                />
              </div>
              <figcaption>
                <h3>
                  <Tit size="s3">{tabs[contentIndex].subject}</Tit>
                </h3>
                <p>{tabs[contentIndex].content}</p>
              </figcaption>
            </figure>
          </TabBanner>
        </article>
        {pdtKey === contentKey ? (
          <SwiperCustom>
            <Swiper
              slidesPerView="auto"
              navigation
              breakpoints={{
                320: {
                  slidesPerGroup: 2,
                },
                641: {
                  slidesPerGroup: 3,
                },
                991: {
                  slidesPerGroup: 4,
                },
              }}
              scrollbar={{ draggable: true }}
            >
              {categoryListSet.map((product) => (
                <SwiperSlide key={product.id}>
                  {/* TODO : 원래 링크에 있던 속성. 기능 정의가 없어 코드만 이관함
                    active={pdtKey === product.categoryCode}
                  */}
                  <GtmButtonDonate
                    type="button"
                    text="후원하기"
                    color="white"
                    className="btn-support-lg-list"
                    full
                    active={pdtKey === product.categoryCode}
                    goto={`/involve/individual/ig/${product.productCode}`}
                    gtmInfo={{
                      ...SelectItemDefaultData,
                      itemId: product.productCode.toString(),
                      itemName: product.productName,
                      itemCategory: SUPPORT_TERM_ONCE,
                      itemListName: product.categoryCodeName,
                      itemBrand: '생명을 구하는 선물',
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  >
                    <CardLayout>
                      <CardImage>
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${product.imgPath});
                          `}
                        />
                      </CardImage>
                      <CardTextArea>
                        <h4>{product.productName}</h4>
                        <h5>{product.productNameEn}</h5>
                        <p>{product.productPrice.toLocaleString()}원</p>
                      </CardTextArea>
                    </CardLayout>
                  </GtmButtonDonate>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperCustom>
        ) : null}
      </div>
    </>
  );
};

export default TabPanel;
