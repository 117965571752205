import {
  GiftVo,
  IGBannerVo,
  SaveLifeControllerService,
} from '@/__generated__/FrontApi';
import Process01 from '@/assets/img/icons/icon_life_saving_gift_process_01@2x.png';
import Process02 from '@/assets/img/icons/icon_life_saving_gift_process_02@2x.png';
import Process03 from '@/assets/img/icons/icon_life_saving_gift_process_03@2x.png';
import Process04 from '@/assets/img/icons/icon_life_saving_gift_process_04@2x.png';
import Banner02 from '@/assets/img/ig_banner_02.png';
import Banner03 from '@/assets/img/ig_banner_03.png';
import Banner04 from '@/assets/img/ig_banner_04.png';
import Banner05 from '@/assets/img/ig_banner_05.png';
import Banner06 from '@/assets/img/ig_banner_06.png';
import Banner07 from '@/assets/img/ig_banner_07.png';
import Banner08 from '@/assets/img/ig_banner_08.png';
import Banner1 from '@/assets/img/img_life_saving_gift_banner_01.png';
import Banner3 from '@/assets/img/img_life_saving_gift_banner_3@2x.png';
import BannerM3 from '@/assets/img/img_life_saving_gift_banner_3_m@2x.png';
import Package01 from '@/assets/img/img_package_01@2x.png';
import Package02 from '@/assets/img/img_package_02@2x.png';
import Package03 from '@/assets/img/img_package_03@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Image from '@/components/Image';
import ImageIcon from '@/components/ImageIcon';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import ProcessList, { DataItem } from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import TabPanel, {
  CategoryData,
  ProductData,
  TabData,
} from '@/components/SubMainTab';
import CartModule from '@/components/Support/CartModule';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper, { SelectItemDefaultIGData } from '@/helpers/GtmHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GtmButtonDonate } from '@/components/Button.gtm';

const tab: TabData[] = [
  {
    // 스페셜 패키지
    id: `04100`,
    subject: `특별한 선물로 어린이의 내일을 응원해 주세요!`,
    content: `재난과 분쟁 속 어린이의 생명을 구하는 특별한 구호물품으로 전 세계 어린이들에게 따뜻한 희망을 선물해 주세요.`,
    image: Banner08,
  },
  {
    // 교육
    id: `04110`,
    subject: `모든 어린이가 읽고 쓰고 셈할 수 있게 도와주세요!`,
    content: `성차별, 조혼, 가사노동, 위험한 등굣길, 부족한 학교 시설과 열악한 환경 등으로 초등학교에 다니지 못하는 취학연령 어린이가 5천 7백만 명입니다.`,
    image: Banner1,
  },
  {
    // 영양
    id: `04120`,
    subject: `어린이들에게 건강한 미래를 선물하세요!`,
    content: `해마다 520만 명이 다섯 번째 생일을 맞지 못하고 소중한 생명을 잃습니다.`,
    image: Banner02,
  },
  {
    // 예방접종
    id: 2,
    subject: `예방 가능한 질병으로부터 아이들을 지켜주세요!`,
    content: `매년 사망하는 5세 미만 어린이 690만 명 중 64%는 폐렴, 설사병, 홍역, 말라리아, HIV 등 예방 가능한 질병으로 목숨을 잃습니다. 2,240만 명은 꼭 필요한 기본 예방접종조차 받지 못합니다.`,
    image: Banner03,
  },
  {
    // 말라리아
    id: `04190`,
    subject: `모기로부터 어린이들의 생명을 구해주세요!`,
    content: `매년 2억명 이상이 말라리아에 감염됩니다. 말라리아로 사망하는 66만 명 가운데 91%는 5세 미만 어린이입니다. 1분마다 1명이 목숨을 잃습니다.`,
    image: Banner04,
  },
  {
    // 건강
    id: `04130`,
    subject: `엄마와 아기의 건강과 행복을 지켜주세요!`,
    content: `해마다 신생아 240만 명이 목숨을 잃습니다. 에이즈 감염자 330만 명이 15세 미만 어린이이며, 에이즈 고아도 1,730만 명에 이릅니다.`,
    image: Banner05,
  },
  {
    // 깨끗한 식수
    id: `04140`,
    subject: `생명을 살리는 깨끗하고 안전한 물이 필요해요!`,
    content: `안전한 식수를 사용하지 못하는 인구 7억 4천 800만 명, 오염된 물로 인한 설사병으로 매일 어린이 800명이 생명을 잃습니다.`,
    image: Banner06,
  },
  {
    // 보호
    id: `04150`,
    subject: `아주 먼 곳까지 따뜻한 손길이 닿을 수 있도록 도와주세요!`,
    content: `개발도상국에는 비포장도로가 많아 외딴 지역 어린이들은 필요한 지원을 받지 못합니다.
    전쟁이나 자연재해 등의 긴급상황이나 기반시설 미비로 어린이들이 보호받을 수 있는 공간도 부족합니다.`,
    image: Banner07,
  },
];

const processes: DataItem[] = [
  {
    id: 1,
    title: `후원`,
    img: Process01,
    desc: [{ dec: `선물하고 싶은 후원물품 선택` }],
  },
  {
    id: 2,
    title: `접수`,
    img: Process02,
    desc: [
      {
        dec: `코펜하겐 물류센터에
      선택된 품목 접수`,
      },
    ],
  },
  {
    id: 3,
    title: `요청`,
    img: Process03,
    desc: [
      {
        dec: `개발도상국 대표사무소에서
      필요한 구호물품 요청`,
      },
    ],
  },
  {
    id: 3,
    title: `전달`,
    img: Process04,
    desc: [
      {
        dec: `긴급한 순서대로
      어린이들에게 전달`,
      },
    ],
  },
];
const CartButton = styled.div`
  width: 180px;
  position: absolute;
  right: 0;
  top: 66px;
  ${breakpoint(800)} {
    position: static;
    width: 100%;
    margin-top: 72px;
  }
`;

const BannerImage = styled.div`
  a {
    display: block;
    width: 100%;
    img {
      max-width: 100%;
    }
  }
`;

const PackageContainer = styled.div`
  ${breakpoint(`tablet`)} {
    overflow-x: auto;
    margin: 0 -20px;
    padding: 0 20px;
  }
`;
const PackageList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -64px;
  padding: 0 49px;

  ${breakpoint(1160)} {
    margin: 0 -5.52vw;
    padding: 0 3.97vw;
  }

  ${breakpoint(`tablet`)} {
    display: inline-flex;
  }

  article {
    width: 33.33%;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    ${breakpoint(1160)} {
      padding: 0 5.52vw;
    }

    ${breakpoint(`tablet`)} {
      width: 234px;
      padding: 0;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    .img-box {
      width: 100%;
      padding-top: 83.53%;
    }
  }
`;

const TextWrap = styled.div`
  margin-bottom: auto;
  padding: 24px;

  ${breakpoint(1160)} {
    padding: 24px 0;
  }

  h3 {
    margin-bottom: 16px;
    line-height: 1.58;
    letter-spacing: -1.2px;
  }

  .price {
    margin-bottom: 16px;
    line-height: 1.6;
    letter-spacing: -1.2px;
  }

  ul {
    width: 100%;
    padding-top: 16px;
    border-top: solid 1px #e5e6e8;

    li {
      line-height: 1.2;
      margin-bottom: 13px;
      letter-spacing: -0.8px;
    }
  }
`;

const LifeSavingGift: React.FC<PageProps> = ({ location }) => {
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [banner, setBanner] = useState<IGBannerVo>();

  // 카테고리 로드
  const loadCategories = useCallback(async () => {
    try {
      const {
        data: giftCategory,
      } = await SaveLifeControllerService.getCategoryCodeListUsingGet();
      setCategories(
        giftCategory.map((category: GiftVo) => ({
          id: category.categoryCode,
          categoryCode: category.categoryCode,
          categoryCodeName: category.categoryCodeName,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 생명을 구하는 선물 로드
  const loadProducts = useCallback(async () => {
    try {
      const {
        data: giftProduct,
      } = await SaveLifeControllerService.getSaveLifeListUsingGet();
      setProducts(
        giftProduct.map((product: GiftVo) => ({
          id: product.productCode,
          categoryCode: product.categoryCode,
          imgPath: product.imgProductAttImgNo1_420,
          productCode: product.productCode,
          productName: product.productName,
          productNameEn: product.productNameEn,
          productPrice: product.productPrice,
          categoryCodeName: product.categoryCodeName,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 배너 로드
  const loadBanner = useCallback(async () => {
    try {
      const {
        data: giftBanner,
      } = await SaveLifeControllerService.getIgMainBannerUsingGet();
      setBanner(giftBanner);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadCategories();
    loadProducts();
    loadBanner();
  }, [loadBanner, loadCategories, loadProducts]);

  // 패키지 상품
  const packageNumber = [`0000974`, `0000973`, `0000986`];
  const filterPack = products?.filter((code: ProductData) =>
    packageNumber.includes(String(code.productCode)),
  );
  // 가격 낮은순 재정렬
  const filterSort = filterPack.sort((a, b) => {
    if (a.productPrice > b.productPrice) return 1;
    if (a.productPrice === b.productPrice) return 0;
    if (a.productPrice < b.productPrice) return -1;
  });

  return (
    <LayoutWithTitle
      location={location}
      title="생명을 구하는 선물"
      description="for every child, inspired gifts"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex">
              <h2>
                <Tit size="s1">
                  <PageComponent id="title1">
                    지구촌 어린이들의
                    <br />
                    생명을 살리는 구호물품
                  </PageComponent>
                </Tit>
              </h2>
              <div>
                <Button
                  color="blue"
                  width={160}
                  className="small-hide"
                  onClick={() => setIsCartModalOpen(true)}
                >
                  <ImageIcon name="cart" /> 장바구니
                </Button>
              </div>
            </div>
            <p>
              <PageComponent id="content1">
                모든 어린이가 건강하게 자라고 학교에 다니며
                <br />
                깨끗한 물을 마실 수 있도록
                <br />
                구호물품으로 특별한 선물을 전해보세요
              </PageComponent>
            </p>
            <Button
              color="blue"
              full
              className="small-show"
              onClick={() => setIsCartModalOpen(true)}
            >
              <ImageIcon name="cart" /> 장바구니
            </Button>
          </SectionHeader>
          {products.length > 0 && (
            <div className="activity-tabpanel">
              <TabPanel
                products={products}
                tabs={tab}
                categories={categories}
                SelectItemDefaultData={SelectItemDefaultIGData}
              />
            </div>
          )}
        </Container>
      </Section>

      <Section className="by-sub-main-layout" backgroundColor="#f8f9fa">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1">이렇게 선물할 수도 있어요</Tit>
            </h2>
          </SectionHeader>
          <PackageContainer>
            <PackageList>
              {filterSort.map((row, index) => (
                <article key={row.id}>
                  {index === 0 && (
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${Package01});
                      `}
                    />
                  )}
                  {index === 1 && (
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${Package02});
                      `}
                    />
                  )}
                  {index === 2 && (
                    <div
                      className="img-box"
                      css={`
                        background-image: url(${Package03});
                      `}
                    />
                  )}
                  <TextWrap>
                    <h3>
                      <Tit size="s3">{row.productName}</Tit>
                    </h3>
                    <Tit size="s4" className="price">
                      {row.productPrice.toLocaleString()}원
                    </Tit>

                    <ul>
                      {row.productCode === `0000974` && (
                        <>
                          <li>소아마비백신 200회분</li>
                          <li>홍역백신 50회분</li>
                          <li>모기장 10개</li>
                        </>
                      )}
                      {row.productCode === `0000973` && (
                        <>
                          <li>영양실조치료식 100봉</li>
                          <li>영양실조치료식우유 10캔</li>
                          <li>복합미량영양소 600봉</li>
                        </>
                      )}
                      {row.productCode === `0000986` && (
                        <>
                          <li>고영양 비스킷 440개</li>
                          <li>긴급구호 비상식량 216개</li>
                          <li>응급처치키트 1개</li>
                        </>
                      )}
                    </ul>
                  </TextWrap>
                  <GtmButtonDonate
                    type="button"
                    text="후원하기"
                    color="blue"
                    size="md"
                    shape="square"
                    full
                    goto={`/involve/individual/ig/${row.productCode}`}
                    gtmInfo={{
                      ...SelectItemDefaultIGData,
                      itemId: row.productCode.toString(),
                      itemName: row.productName,
                      itemCategory: SUPPORT_TERM_ONCE,
                      itemListName: row.categoryCodeName,
                      itemBrand: '생명을 구하는 선물',
                    }}
                    onClickPreEvent={() => {
                      // TODO onClick before call gtm
                      // alert('click');
                    }}
                  />
                </article>
              ))}
            </PackageList>
          </PackageContainer>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <Tit size="s1">
              후원자님이 보내주신
              <br />
              구호물품은 이렇게 전달합니다
            </Tit>
          </SectionHeader>
          <ProcessList itemData={processes} arrow />
        </Container>
      </Section>
      {banner && (
        <Section className="by-sub-main-layout">
          <Container>
            <SectionHeader>
              <Tit size="s1">캠페인</Tit>
            </SectionHeader>
            <BannerImage>
              {banner.bannerTargetUrl ? (
                <LinkSafe
                  to={banner.bannerTargetUrl}
                  target={banner.bannerTargetClick}
                >
                  <Image
                    pcSrc={banner.pcBannerAttNoTn}
                    mobileSrc={banner.mobileBannerAttNoTn}
                    alt="방패 선물하기 백신 한 방울, 생명을 구하는 방패 하나"
                  />
                </LinkSafe>
              ) : (
                <Image
                  pcSrc={banner.pcBannerAttNoTn}
                  mobileSrc={banner.mobileBannerAttNoTn}
                  alt="방패 선물하기 백신 한 방울, 생명을 구하는 방패 하나"
                />
              )}
            </BannerImage>
          </Container>
        </Section>
      )}
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <Tit size="s1">
              후원자님께서 보내주신
              <br />
              선물이 준 도움
            </Tit>
          </SectionHeader>
          <BannerImage>
            <a href="/igreport/2019/" target="_blank">
              <Image
                pcSrc={Banner3}
                mobileSrc={BannerM3}
                alt="2019년 생명을 구하는 선물 전달보고서"
              />
            </a>
          </BannerImage>
        </Container>
      </Section>
      {isCartModalOpen && (
        <CartModule isOpen onRequestClose={() => setIsCartModalOpen(false)} />
      )}
    </LayoutWithTitle>
  );
};

export default LifeSavingGift;
